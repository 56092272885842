import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"
import { v4 as uuidv4 } from "uuid"

import Link from "../UI/Link"
import slugify from "../../utils/slugify"
import {
  sectionClass,
  successSectionClass,
  rowClass,
  formControlClass,
  successContainerClass,
  successMsgClass,
  sendBtnClass,
  checkboxGroupClass,
  checkboxContainerClass,
  checkmarkClass
} from "./FundraisingInterestForm.module.scss"

export const fragment = graphql`
  fragment fundraisingInterestFormFragment on WpPage_Flexlayouts_FlexibleLayouts_FundraisingInterestForm {
    activities {
      activity
    }
    conditionsLink {
      ... on WpPage {
        link
      }
    }
    privacyLink {
      ... on WpPage {
        link
      }
    }
    submitMessage
    emailTo
  }
`

const FundraisingInterestForm = ({
  activities,
  conditionsLink,
  privacyLink,
  submitMessage,
  emailTo
}) => {
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({ initial: true })
  const [selectedActivities, setSelectedActivities] = useState({})
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const handleGroupCheckboxClick = ({ target: { id } }) => {
    setSelectedActivities(selected => ({ ...selected, [id]: !selected[id] }))
  }

  const validate = event => {
    let form = event.currentTarget

    if (form.checkValidity() === false) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget
    let activityList = ""

    for (let key in selectedActivities) {
      if (selectedActivities[key]) {
        const activity = key.replace(/\-/g, " ")
        activityList += `${activity}; `
      }
    }

    activityList = activityList.replace(/;\s?$/, ".")

    const data = {
      firstname: form.elements.firstname.value,
      lastname: form.elements.lastname.value,
      email: form.elements.email.value,
      phone: form.elements.phone.value,
      message: form.elements.message.value,
      addressLine1: form.elements.addressLine1.value,
      addressLine2: form.elements.addressLine2.value,
      postaltown: form.elements.postaltown.value,
      addressLine4: form.elements.addressLine4.value,
      postcode: form.elements.postcode.value,
      country: form.elements.country.value,
      interestedIn: activityList,
      emailTo: emailTo
    }

    const postForm = async () => {
      try {
        setIsSubmitting(true)
        const response = await axios.post(
          `${process.env.FUNDRAISING_FORM_URL}`,
          queryString.stringify(data)
        )
        setStatus({
          success: submitMessage,
          initial: false
        })
      } catch (exception) {
        setIsSubmitting(false)
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`
        })
      }
    }
    postForm()
  }

  return (
    <section
      className={status && status.success ? successSectionClass : sectionClass}>
      {status && status.initial && (
        <Container className="py-5 bg-purple">
          <Row className={rowClass}>
            <Col md={10} className="mx-auto">
              <Form
                id="FundraisingInterestForm"
                noValidate
                onChange={validate}
                onSubmit={handleSubmit}
                method="post">
                <>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstname">
                        <Form.Label for="firstname">First Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="firstname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="lastname">
                        <Form.Label for="lastname">Last Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="lastname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label for="email">Email Address*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="email"
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="phone">
                        <Form.Label for="phone">Phone number*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="phone"
                          type="tel"
                          minlength="11"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="py-mid4">
                    <Row>
                      <Col>
                        <Form.Group controlId="addressLine1">
                          <Form.Label for="addressLine1">
                            Street Address*
                          </Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="addressLine1"
                            type="text"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="addressLine2">
                          <Form.Label for="addressLine2">
                            Address Line 2
                          </Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="addressLine2"
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="postaltown">
                          <Form.Label for="postaltown">Town / City*</Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="postaltown"
                            type="text"
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group controlId="addressLine4">
                          <Form.Label for="addressLine4">
                            County / State / Region{" "}
                          </Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="addressLine4"
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="postcode">
                          <Form.Label for="postcode">
                            Postcode / ZIP*
                          </Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="postcode"
                            type="text"
                            required
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group controlId="country">
                          <Form.Label for="country">Country*</Form.Label>
                          <Form.Control
                            className={formControlClass}
                            name="country"
                            type="text"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <Row className="mb-mid4">
                    <Col className="mb-3">
                      <p>
                        If there are any particular fundraising activities that
                        interest you, please feel free to indicate below by
                        selecting any/all that apply:
                      </p>
                    </Col>
                    {activities.map(item => {
                      const isChecked = selectedActivities[slugify(item.activity)]

                      return (
                        <Col md={6} key={`activity-${uuidv4()}`}>
                          <div
                            className={`${checkboxGroupClass} form-group form-check`}>
                            <label
                              className={`${checkboxContainerClass} form-check-label text-start`}
                              htmlFor={slugify(item.activity)}>
                              {isChecked ? (
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  id={slugify(item.activity)}
                                  name={slugify(item.activity)}
                                  onChange={handleGroupCheckboxClick}
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  id={slugify(item.activity)}
                                  name={slugify(item.activity)}
                                  onChange={handleGroupCheckboxClick}
                                />
                              )}
                              <span className={checkmarkClass}></span>
                              {item.activity}
                            </label>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="message">
                        <Form.Label for="message">Your message*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="message"
                          as="textarea"
                          rows={4}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} lg={7}>
                      <div
                        className={`${checkboxGroupClass} form-group form-check`}>
                        <label
                          className={`${checkboxContainerClass} form-check-label text-start`}
                          htmlFor="terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="terms"
                            name="terms"
                            required
                          />
                          <span className={checkmarkClass}/>I agree to the
                          EPT{" "}
                          <Link
                            classes="linkClass"
                            link={privacyLink?.link}
                            title="Privacy Policy"
                          />
                          {/*{" "} and{" "}
                          <Link
                            classes="linkClass"
                            link={conditionsLink?.link}
                            title="Terms"
                          /> */}
                          {"."}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button
                          type="submit"
                          className={sendBtnClass}
                          disabled={submitDisabled}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass} dangerouslySetInnerHTML={{ __html: status.success}}/>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}

export default FundraisingInterestForm
